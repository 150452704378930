export default async function getMenuData() {

  const devMenu = [
    {
      category: true,
      title: 'Dashboards',
      key: 'q7r3hr',
    },
    {
      title: 'Dashboards',
      key: '__dashboard',
      url: '/dashboard',
      icon: 'fe fe-home',
      children: [
        {
          title: 'Dashboard Alpha',
          key: 'iasepr',
          url: '/dashboard/alpha',
        },
        {
          title: 'Dashboard Beta',
          key: 'oi2iv',
          url: '/dashboard/beta',
        },
        {
          title: 'Dashboard Gamma',
          key: 'amhwha',
          url: '/dashboard/gamma',
        },
        {
          title: 'Crypto Terminal',
          key: '0emfve',
          url: '/dashboard/crypto',
        },
      ],
    },
    {
      category: true,
      title: 'Apps & Pages',
      key: '2t2ghm',
    },
    {
      title: 'Apps',
      key: '6rq4ze6',
      url: '/apps',
      icon: 'fe fe-database',
      children: [
        {
          title: 'Profile',
          key: 'e38wke',
          url: '/apps/profile',
        },
        {
          title: 'Calendar',
          key: 'y08dt9',
          url: '/apps/calendar',
        },
        {
          title: 'Gallery',
          key: 'lzh7g9',
          url: '/apps/gallery',
        },
        {
          title: 'Messaging',
          key: 'jdwic9',
          url: '/apps/messaging',
        },
        {
          title: 'Mail',
          key: '4dwoxc',
          url: '/apps/mail',
        },
      ],
    },
    {
      title: 'Extra Apps',
      key: 'c20gta',
      url: '/extra-apps',
      icon: 'fe fe-hard-drive',
      children: [
        {
          title: 'Github Explore',
          key: '8ba63s',
          url: '/extra-apps/github-explore',
        },
        {
          title: 'Github Discuss',
          key: 'b0rmp9',
          url: '/extra-apps/github-discuss',
        },
        {
          title: 'Digitalocean Droplets',
          key: 'cmzpxs',
          url: '/extra-apps/digitalocean-droplets',
        },
        {
          title: 'Digitalocean Create',
          key: 'ldmum',
          url: '/extra-apps/digitalocean-create',
        },
        {
          title: 'Google Analytics',
          key: 'dae7ki',
          url: '/extra-apps/google-analytics',
        },
        {
          title: 'Wordpress Post',
          key: '4iz2vq',
          url: '/extra-apps/wordpress-post',
        },
        {
          title: 'Wordpress Posts',
          key: '36v7m',
          url: '/extra-apps/wordpress-posts',
        },
        {
          title: 'Wordpress Add',
          key: 'uo8k1f',
          url: '/extra-apps/wordpress-add',
        },
        {
          title: 'Todoist List',
          key: 'dhg65',
          url: '/extra-apps/todoist-list',
        },
        {
          title: 'Jira Dashboard',
          key: 'jdhtkr',
          url: '/extra-apps/jira-dashboard',
        },
        {
          title: 'Jira Agile Board',
          key: '98jp2',
          url: '/extra-apps/jira-agile-board',
        },
        {
          title: 'Helpdesk Dashboard',
          key: 'brmh5',
          url: '/extra-apps/helpdesk-dashboard',
        },
      ],
    },
    {
      title: 'Ecommerce',
      key: 'aw5fgi',
      url: '/ecommerce',
      icon: 'fe fe-shopping-cart',
      children: [
        {
          title: 'Dashboard',
          key: 'agiw5f',
          url: '/ecommerce/dashboard',
        },
        {
          title: 'Orders',
          key: '2av8s2',
          url: '/ecommerce/orders',
        },
        {
          title: 'Product Catalog',
          key: 's1khgb',
          url: '/ecommerce/product-catalog',
        },
        {
          title: 'Product Details',
          key: 'vd6efh',
          url: '/ecommerce/product-details',
        },
        {
          title: 'Cart',
          key: 'y4smrt',
          url: '/ecommerce/cart',
        },
      ],
    },
    {
      title: 'Auth Pages',
      key: 'czhl14',
      url: '/auth',
      icon: 'fe fe-user',
      children: [
        {
          title: 'Login',
          key: 'fbre',
          url: '/auth/login',
        },
        {
          title: 'Forgot Password',
          key: 'jtjsjp',
          url: '/auth/forgot-password',
        },
        {
          title: 'Register',
          key: 'v2u9ie',
          url: '/auth/register',
        },
        {
          title: 'Lockscreen',
          key: '61qkmj',
          url: '/auth/lockscreen',
        },
        {
          title: 'Page 404',
          key: '92rb3h',
          url: '/auth/404',
        },
        {
          title: 'Page 500',
          key: 'tbmkob',
          url: '/auth/500',
        },
      ],
    },
    {
      category: true,
      title: 'UI Kits',
      key: 'evz6c8',
    },
    {
      title: 'Ant Design',
      key: 'qelxw',
      url: '/ui-kits/antd',
      icon: 'fe fe-bookmark',
    },
    {
      title: 'Bootstrap',
      key: 'l1gqx8',
      url: '/ui-kits/bootstrap',
      icon: 'fe fe-bookmark',
    },
    {
      category: true,
      title: 'Components',
      key: '2kw0b',
    },
    {
      title: 'Widgets',
      key: '90c2s4',
      url: '/widgets',
      icon: 'fe fe-image',
      children: [
        {
          title: 'General',
          key: 'oomius',
          url: '/widgets/general',
        },
        {
          title: 'Lists',
          key: '40yu9r',
          url: '/widgets/lists',
        },
        {
          title: 'Tables',
          key: 'nuf1vf',
          url: '/widgets/tables',
        },
        {
          title: 'Charts',
          key: 'mw15uj',
          url: '/widgets/charts',
        },
      ],
    },
    {
      title: 'Cards',
      key: 'c075qe',
      url: '/cards',
      icon: 'fe fe-credit-card',
      children: [
        {
          title: 'Basic Cards',
          key: 'j3q7kr',
          url: '/cards/basic',
        },
        {
          title: 'Tabbed Cards',
          key: '25rap8',
          url: '/cards/tabbed',
        },
      ],
    },
    {
      title: 'Tables',
      key: '62sqvb',
      url: '/tables',
      icon: 'fe fe-grid',
      children: [
        {
          title: 'Ant Design',
          key: '3gyey5',
          url: '/tables/antd',
        },
        {
          title: 'Bootstrap',
          key: 'amvqq',
          url: '/tables/bootstrap',
        },
      ],
    },
    {
      title: 'Charts',
      key: '783vor',
      url: '/charts',
      icon: 'fe fe-pie-chart',
      children: [
        {
          title: 'Chartist.js',
          key: 'jlx0h',
          url: '/charts/chartistjs',
        },
        {
          title: 'Chart.js',
          key: '7yv6f',
          url: '/charts/chartjs',
        },
        {
          title: 'C3',
          key: 'dxcyvo',
          url: '/charts/C3',
        },
      ],
    },
    {
      title: 'Icons',
      key: 'fla1pc',
      url: '/icons',
      icon: 'fe fe-star',
      children: [
        {
          title: 'Feather Icons',
          key: '677srg',
          url: '/icons/feather-icons',
        },
        {
          title: 'Fontawesome',
          key: '3ghw6l',
          url: '/icons/fontawesome',
        },
        {
          title: 'Linearicons',
          key: 'bwvp9',
          url: '/icons/linearicons-free',
        },
        {
          title: 'Icomoon Free',
          key: 'lwyu1r',
          url: '/icons/icomoon-free',
        },
      ],
    },
    {
      category: true,
      title: 'Advanced',
      key: 'krwgag',
    },
    {
      title: 'Form Examples',
      key: 'jjiyzn',
      url: '/advanced/form-examples',
      icon: 'fe fe-menu',
    },
    {
      title: 'Email Templates',
      key: '81n64m',
      url: '/advanced/email-templates',
      icon: 'fe fe-mail',
    },
    {
      title: 'Pricing Tables',
      key: 'jk7lfk',
      url: '/advanced/pricing-tables',
      icon: 'fe fe-command',
    },
    {
      title: 'Invoice',
      key: 'kf8csg',
      url: '/advanced/invoice',
      icon: 'fe fe-file-text',
    },
    {
      title: 'Utilities',
      key: 'buvas4',
      url: '/advanced/utilities',
      icon: 'fe fe-hard-drive',
    },
    {
      title: 'Grid',
      key: 'thqbnl5',
      url: '/advanced/grid',
      icon: 'fe fe-grid',
    },
    {
      title: 'Typography',
      key: '59lm7a',
      url: '/advanced/typography',
      icon: 'fe fe-type',
    },
    {
      title: 'Colors',
      key: '97ptgj',
      url: '/advanced/colors',
      icon: 'fe fe-feather',
    },
    {
      title: 'Nested Items',
      key: '7nnrzj',
      url: '/nested',
      icon: 'fe fe-layers',
      children: [
        {
          title: 'Level 1',
          key: '9314t',
          url: '/nested/1',

          children: [
            {
              title: 'Level 2',
              key: '423uh',
              url: '/nested/2',
            },
          ],
        },
      ],
    }, 

  ]

  const liveMenu = [    
    {
      category: true,
      title: 'Catalog',
      roles: ['admin'],
    },
    {
      title: 'Products',
      key: 'products',
      icon: 'fe fe-grid',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'Products',
          key: 'catalogProducts',
          url: '/catalog/products',
        },
        {
          title: 'Categories',
          key: 'catalogCategories',
          url: '/catalog/categories',
        },
      ],
    },
    {
      title: 'Stores',
      key: 'stores',
      icon: 'fe fe-shopping-bag',
      roles: ['admin', 'support'], // set user roles with access to this route
      children: [
        {
          title: 'Stores',
          key: 'catalogStores',
          url: '/catalog/stores',
        },
        {
          title: 'Store Types',
          key: 'catalogStoreTypes',
          url: '/catalog/store-types',
        },
        {
          title: 'Store Type Collections',
          key: 'catalogStoreTypeCollections',
          url: '/catalog/store-type-collections',
        },
      ],
    },
    {
      title: 'Filters',
      key: 'filters',
      icon: 'fe fe-filter',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'Filters',
          key: 'catalogFilters',
          url: '/catalog/filters',
        },
        {
          title: 'Filter Groups',
          key: 'catalogFilterGroups',
          url: '/catalog/filter-group',
        },
      ],
    },
    {
      title: 'Help',
      key: 'help',
      icon: 'fe fe-life-buoy',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'Help',
          key: 'catalogHelp',
          url: '/catalog/help',
        },
        {
          title: 'Help Categories',
          key: 'catalogHelpCategories',
          url: '/catalog/help-category',
        },
      ],
    },
    {
      category: true,
      title: 'ECommerce',
      roles: ['admin', 'support'],
    },
    {
      title: 'Sales',
      key: 'sales',
      icon: 'fe fe-shopping-cart',
      roles: ['admin', 'support'], // set user roles with access to this route
      children: [
        {
          title: 'Orders',
          key: 'ecommerceOrders',
          url: '/ecommerce/orders',
        },
        {
          title: 'Now',
          key: 'ecommerceNow',
          url: '/ecommerce/now',
        },
      ],
    },
    {
      category: true,
      title: 'Marketing',
      roles: ['admin'],
    },
    {
      title: 'General',
      key: 'marketing-general',
      icon: 'fe fe-bell',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'App Start',
          key: 'marketingAppStart',
          url: '/marketing/app-starts',
        },
        {
          title: 'Coupons',
          key: 'marketingCoupons',
          url: '/marketing/coupons',
        },
      ],
    },
    {
      title: 'Sliders',
      key: 'sliders',
      icon: 'fe fe-book-open',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'Sliders',
          key: 'marketingSliders',
          url: '/marketing/sliders',
        },
      ],
    },
    {
      category: true,
      title: 'Users',
      roles: ['admin'],
    },
    {
      title: 'Customers',
      key: 'customers',
      icon: 'fe fe-users',
      count: 532,
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'Customers',
          key: 'userCustomers',
          url: '/users/customers',
        },
        {
          title: 'Customer Groups',
          key: 'userCustomerGroups',
          url: '/users/customer-groups',
        },
      ],
    },
    {
      title: 'Merchants',
      key: 'merchants',
      icon: 'fe fe-user-check',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'Merchants',
          key: 'userMerchants',
          url: '/users/merchants',
        },
        {
          title: 'New Merchant',
          key: 'userNewMerchant',
          url: '/users/merchants/edit',
        },
      ],
    },
    {
      title: 'Admins',
      key: 'admins',
      icon: 'fe fe-user-plus',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'Admins',
          key: 'userAdmins',
          url: '/users/admins',
        },
        {
          title: 'Admin Groups',
          key: 'userAdminGroups',
          url: '/users/admin-groups',
        },
      ],
    },
    {
      category: true,
      title: 'Finance',
      roles: ['admin'],
    },
    {
      title: 'Wallet',
      key: 'wallet',
      icon: 'fe fe-dollar-sign',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'Customer',
          key: 'walletCustomers',
          url: '/wallet/customer-wallet',
        },
        {
          title: 'Merchant',
          key: 'walletMerchant',
          url: '/wallet/merchant-wallet',
        },
        {
          title: 'Admin',
          key: 'walletAdmin',
          url: '/wallet/admin-wallet',
        },
      ],
    },
    {
      category: true,
      title: 'Configuration',
      roles: ['admin'],
    },
    {
      title: 'Localisation',
      key: 'localisation',
      icon: 'fe fe-globe',
      roles: ['admin'],
      children: [
        {
          title: 'Currencies',
          key: 'localisationCurrencies',
          url: '/configuration/currencies',
        },
        {
          title: 'Languages',
          key: 'localisationLanguages',
          url: '/configuration/languages',
        },
        {
          title: 'Cities',
          key: 'localisationCities',
          url: '/configuration/cities',
        },
        {
          title: 'States',
          key: 'localisationStates',
          url: '/configuration/states',
        },
      ],
    },
    {
      title: 'App',
      key: 'settings-app',
      icon: 'fe fe-smartphone',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'General',
          key: 'appGeneral',
          url: '/configuration/app-general',
        },
        {
          title: 'Onboardings',
          key: 'appOnboardings',
          url: '/configuration/onboardings',
        },
        {
          title: 'Menu',
          key: 'appMenu',
          url: '/configuration/app-menu',
        },
      ],
    },
    {
      title: 'Settings',
      key: 'settings',
      icon: 'fe fe-settings',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'General',
          key: 'settingsGeneral',
          url: '/catalog/settings-general',
        },
      ],
    },
    {
      title: 'Extensions',
      key: 'extensions',
      icon: 'fe fe-codepen',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'Payment',
          key: 'extensionsPayment',
          url: '/configuration/payment-methods',
        },
        {
          title: 'Delivery',
          key: 'extensionsDelivery',
          url: '/configuration/delivery-methods',
        },
      ],
    },
    {
      title: 'Statuses',
      key: 'statuses',
      icon: 'fe fe-disc',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'Orders',
          key: 'stausesOrders',
          url: '/configuration/order-status',
        },
        {
          title: 'Delivery System',
          key: 'statusDeliverySystem',
          url: '/configuration/delivery-system-statuses',
        },
      ],
    },
    {
      title: 'Templates',
      key: 'templates',
      icon: 'fe fe-file-plus',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'Notifications',
          key: 'templatesNotifications',
          url: '/configuration/notifications',
        },
        {
          title: 'Pages',
          key: 'templatesPages',
          url: '/configuration/page',
        },
      ],
    },
    {
      category: true,
      title: 'Reports',
      roles: ['admin'],
    },
    {
      title: 'Sales',
      key: 'report-sales',
      icon: 'fe fe-shopping-cart',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'Orders',
          key: 'reportSalesOrders',
          url: '/reports/?type=sales-orders',
        },
        {
          title: 'Returns',
          key: 'reportSalesReturns',
          url: '/reports/?type=sales-returns',
        },
        {
          title: 'Shipping',
          key: 'reportSalesShipping',
          url: '/reports/?type=sales-shipping',
        },
        {
          title: 'Coupon',
          key: 'reportSalesCoupon',
          url: '/reports/?type=sales-coupon',
        },
        {
          title: 'Commission',
          key: 'reportSalesCommission',
          url: '/reports/?type=sales-commission',
        },
      ],
    },
    {
      title: 'Merchant',
      key: 'report-merchant',
      icon: 'fe fe-shopping-bag',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'Signup',
          key: 'reportMerchantSignup',
          url: '/reports/?type=merchant-signup',
        },
        {
          title: 'Orders',
          key: 'reportMerchantOrders',
          url: '/reports/?type=merchant-orders',
        },
        {
          title: 'Returns',
          key: 'reportMerchantReturns',
          url: '/reports/?type=merchant-returns',
        },
      ],
    },
    {
      title: 'Products',
      key: 'report-products',
      icon: 'fe fe-grid',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'Products',
          key: 'reportProductsPurchased',
          url: '/reports/?type=products-purchased',
        },
        {
          title: 'Returns',
          key: 'reportProductReturns',
          url: '/reports/?type=product-returns',
        },
      ],
    },
    {
      title: 'Customers',
      key: 'report-customers',
      icon: 'fe fe-users',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'Signup',
          key: 'reportCustomerSignup',
          url: '/reports/?type=customer-signup',
        },
        {
          title: 'Orders',
          key: 'reportCustomerOrders',
          url: '/reports/?type=customer-orders',
        },
        {
          title: 'Returns',
          key: 'reportCustomerReturns',
          url: '/reports/?type=customer-returns',
        },
        {
          title: 'Credit',
          key: 'reportCustomerCredit',
          url: '/reports/?type=customer-credit',
        },
        {
          title: 'Reward Points',
          key: 'reportCustomerRewardPoints',
          url: '/reports/?type=customer-reward-points',
        },
      ],
    },
    {
      category: true,
      title: 'Miscellaneous',
      roles: ['admin'],
    },
    {
      title: 'Tools',
      key: 'tools',
      icon: 'fe fe-scissors',
      roles: ['admin'], // set user roles with access to this route
      children: [
        {
          title: 'Export/Import',
          key: 'toolsExportImport',
          url: '/miscellaneous/export-import',
        },
        {
          title: 'Jobs',
          key: 'toolsJobs',
          url: '/miscellaneous/jobs',
        },
        {
          title: 'Scripts',
          key: 'toolsScripts',
          url: '/miscellaneous/scripts',
        },
      ],
    },
  ]

  return process.env.NODE_ENV === 'development' ?  [...liveMenu, ...devMenu] :  [...liveMenu] 
  
}
