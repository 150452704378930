import {
    ApolloClient, InMemoryCache, HttpLink, split, defaultDataIdFromObject
  } from '@apollo/client';
  import { getMainDefinition } from '@apollo/client/utilities';
  import { WebSocketLink } from '@apollo/client/link/ws';
  import {onError} from '@apollo/client/link/error'
  import { setContext } from "@apollo/client/link/context";

const useConfigClient = () => {

// eslint-disable-next-line no-unused-vars
const errorLink = onError(({graphqlErrors,networkError})=>{
    if(graphqlErrors){
      // eslint-disable-next-line array-callback-return
      graphqlErrors.map(({message})=>{
       console.log(`Graphql error ${message}`)
      })
    }
  })

  const cache = new InMemoryCache({
    dataIdFromObject(responseObject) {
      return responseObject.language_id ? `${responseObject.__typename}:${responseObject.id}:${responseObject.language_id}` : defaultDataIdFromObject(responseObject);      
    }

  })
  
  
  const httpLink = new HttpLink({
    uri: `https://${process.env.REACT_APP_GRAPHQL_ENDPOINT}`,
    headers: {
      // 'X-Hasura-Admin-Secret': '',
      // 'Authorization': `Bearer ${authTokenStorage}`,
    },
  });

  let token = JSON.parse(localStorage.getItem('accessToken'));  
  const wsLink = new WebSocketLink({
    uri: `wss://${process.env.REACT_APP_GRAPHQL_ENDPOINT}`,
    options: {
      reconnect: true,
      connectionParams:{
        headers: {
          // 'X-Hasura-Admin-Secret': '',
          'Authorization': `Bearer ${token}`,
        },
      }
    },
  });
  
  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    token = JSON.parse(localStorage.getItem('accessToken'));
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
      }
    }
  });
   
  // Initialize Apollo Client
  const client = new ApolloClient({
    cache,  
    link: authLink.concat(link),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      mutate:{
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      }
    }
  });

  return client;

}

export default useConfigClient;