import localeAntd from 'antd/es/locale/en_US'

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',
  'component.tagSelect.expand': 'Expand',
  'component.tagSelect.collapse': 'Collapse',
  'component.tagSelect.all': 'All',
  'component.common.success': 'Success',
  'component.common.heading': 'Heading',
  'component.common.subHeading': 'Sub Heading',
  'component.common.error': 'Error',
  'component.common.apiKey' : 'API Key',
  'component.common.add.success': 'Added information successfully',
  'component.common.update.success': 'Updated information successfully',
  'component.common.update.error': 'Something went wrong!',
  'component.common.update.error.700': 'Something went wrong!',
  'component.common.update.error.701': 'There are cities attached to this state',
  'component.common.delete.success': 'Deleted successfully',
  'component.common.save': 'Save Information',
  'component.common.update': 'Update Information',
  'component.common.title': 'Title',
  'component.common.description': 'Description',
  'component.common.name': 'Name',
  'component.common.label': 'Label',
  'component.common.code': 'Code',
  'component.common.status': 'Status',
  'component.common.action': 'Action',
  'component.common.edit': 'Edit',
  'component.common.delete': 'Delete',
  'component.common.enable': 'Enable',
  'component.common.disable': 'Disable',
  'component.common.filter': 'Filter',
  'component.common.clear': 'Clear',
  'component.common.enabled': 'Enabled',
  'component.common.disabled': 'Disabled',
  'component.common.all': 'All',
  'component.common.new': 'New',
  'component.common.information': 'Information',
  'component.common.submit': 'Submit',
  'component.common.cancel': 'Cancel',
  'component.common.loading': 'Loading...',
  'component.common.locale': 'Locale',
  'component.common.cdn': 'CDN Link',
  'component.common.sort_order': 'Sort order',
  'component.common.icon': 'Icon',
  'component.common.image': 'Image',
  'component.common.ltr': 'LTR',
  'component.common.symbol_left': 'Symbol Left',
  'component.common.symbol_right': 'Symbol Right',
  'component.common.decimal_place': 'Decimal Place',
  'component.common.value': 'Value',
  'component.common.upload': 'Upload',
  'component.common.enter': 'Enter',
  'component.common.slug': 'Slug',
  'component.common.group': 'Group',
  'component.common.question': 'Question',
  'component.common.answer': 'Answer',
  'component.common.width': 'Width',
  'component.common.date_start': 'Start Date',
  'component.common.date_end': 'End Date',
  'component.common.location': 'Location',
  'component.common.slider': 'Slider',
  'component.common.sliders': 'Sliders',
  'component.common.dates': 'Dates',
  'component.common.featured': 'Featured',
  'component.common.yes': 'Yes',
  'component.common.no': 'No',
  'component.common.collection': 'Collection',
  'component.common.app': 'App',
  'component.common.settings': 'Settings',
  'component.common.coming_soon': 'Coming Soon',
  'component.common.order_image': 'Order Image',
  'component.common.created_at': 'Date Added',
  'component.common.updated_at': 'Date Updated',
  'component.common.type': 'Type',
  'component.common.credit': 'Credit',
  'component.common.debit': 'Debit',
  'component.common.api_key': 'API Key',
  'component.common.1': 'Pending',
  'component.common.2': 'Processing',
  'component.common.3': 'Success',
  'component.common.4': 'Failed',
  'component.localisation': 'Localisation',
  'component.configuration.country': 'Country',
  'component.configuration.countries_plural': 'Countries',
  'component.configuration.states': 'State',
  'component.configuration.states_plural': 'States',
  'component.configuration.cities': 'City',
  'component.configuration.cities_plural': 'Cities',
  'component.configuration.languages': 'Language',
  'component.configuration.languages_plural': 'Languages',
  'component.configuration.payment_methods': 'Payment Method',
  'component.configuration.payment_methods_plural': 'Payment Methods',
  'component.configuration.delivery_methods': 'Delivery Method',
  'component.configuration.delivery_methods_plural': 'Delivery Methods',
  'component.configuration.timezones': 'Timezone',
  'component.catalog': 'Catalog',
  'component.catalog.products': 'Product',
  'component.catalog.products_plural': 'Products',
  'component.catalog.categories': 'Category',
  'component.catalog.categories_plural': 'Categories',
  'component.catalog.filters': 'Filter',
  'component.catalog.filters_plural': 'Filters',
  'component.catalog.filter_group': 'Filter Group',
  'component.catalog.filter_group_plural': 'Filter Groups',
  'component.catalog.store_types': 'Store Type',
  'component.catalog.store_types_plural': 'Store Types',
  'component.catalog.store_type_collections': 'Store Type Collection',
  'component.catalog.store_type_collections_plural': 'Store Type Collections',
  'component.catalog.stores': 'Store',
  'component.catalog.stores_plural': 'Stores',
  'component.catalog.stores.layout': 'Layout',
  'component.catalog.stores.asap': 'ASAP',
  'component.catalog.stores.asap_difference': 'ASAP Difference',
  'component.catalog.stores.asap_description': 'ASAP Description',
  'component.catalog.stores.delivery_method_name': 'Delivery Method Name',
  'component.catalog.stores.free_delivery': 'Free Delivery',
  'component.catalog.stores.free_delivery_amount': 'Free Delivery Amount',
  'component.catalog.stores.delivery_method_status' : 'Status',
  'component.catalog.stores.delivery_charge': 'Delivery Charges',
  'component.catalog.stores.delivery_charge_ds': 'Delivery System Charges',
  'component.catalog.stores.minimum_order_amount': 'Minimum Order Amount',
  'component.catalog.stores.maximum_order_amount': 'Maximum Order Amount',
  'component.catalog.stores.time_difference': 'Buffer Time',
  'component.catalog.help': 'Help',
  'component.catalog.help_plural': 'Help',
  'component.common.storeName': 'Store Name',
  'component.common.storeStatus': 'Store Status',
  'component.common.message': 'Message',
  'component.common.color': 'Color',
  'component.common.merchantName': 'Merchant',
  'component.common.commisionName': 'Platform Commission (%)',
  'component.common.fixedCommision': 'Platform Commission (Fixed)',
  'component.common.storeDiscount': 'Set Store Discount',
  'component.common.storeType': 'Store Type',
  'component.common.selectCateg': 'Select Categ',
  'component.common.averagePrice': 'Average Price Per Meal',
  'component.common.averagePrepration': 'Average Prepration Time',
  'component.common.phone': 'Phone',
  'component.common.number': 'Number',
  'component.common.time': 'Time',
  'component.common.price': 'Price',
  'component.common.logo': 'Logo Upload',
  'component.common.banner': 'Banner Upload',
  'component.common.storeStreet': 'Store Street',
  'component.common.storeCity': 'Store City',
  'component.common.zipCode': 'Zip Code',
  'component.common.metaTitle': 'Meta Title',
  'component.common.metaKeyword': 'Meta Keyword',
  'component.common.metaDescription': 'Meta Description',
  'component.common.notice': 'Notice',
  'component.common.deliveryZipCode': 'Delivery Zip Codes',
  'component.common.timeslot': 'No. of days in timeslots',
  'component.common.deliveryType': 'Delivery Type',
  'component.common.minOrder': 'Minimum Order',
  'component.common.homeDeliveryTime': 'Home Delivery Time Difference',
  'component.common.pickTime': 'Pick Time Difference',
  'component.common.deliveryAmount': 'Free Delivery Amount',
  'component.common.deliveryTimePerUnit': 'Delivery Time per unit Distance',
  'component.common.deliveryFee': 'Delivery Fee',
  'component.common.useDelivery': 'Use Delivery System',
  'component.common.amount': 'Amount',
  'component.common.mode': 'Mode',
  'component.common.online': 'Online',
  'component.common.cod': 'Cash on Delivery',
  'component.common.delivery': 'Delivery',
  'component.common.pickup': 'Pickup',
  'component.common.pick_and_drop': 'Pick & Drop',
  'component.common.order': 'Order',
  'component.common.customer': 'Customer',
  'component.common.contact': 'Contact',
  'component.common.seller': 'Seller',
  'component.common.reviews': 'Reviews',
  'component.common.referral': 'Referral',
  'component.common.vendororder': 'Vendor Order',
  'component.common.vendorreturn':'Vendor Return',
  'component.common.loginotp': 'Login OTP',
  'component.common.registerotpp': 'Register OTP',
  'component.common.invoice': 'Invoice',
  'component.common.return': 'Return',
  'component.common.comingsoon': 'Coming Soon',
  'component.common.delivery_locations': 'Delivery Locations',
  'component.ecommerce.needToPay': 'Need To Pay',
  'component.ecommerce.ordersHistory': 'Add Order History',
  'component.ecommerce.hash': '#',
  'component.ecommerce.review': 'Review',
  'component.ecommerce.reviewRating': 'Review Rating',
  'component.ecommerce.reviewBy': 'Review By',
  'component.ecommerce.order_id': 'Order Id',
  'component.ecommerce.adminComission': 'Admin Commission',
  'component.ecommerce.expected_delivery_date': 'Expected Delivery Date',
  'component.ecommerce.timeslot': 'Timeslot',
  'component.ecommerce.payment_method': 'Payment Method',
  'component.ecommerce.adjustedAmount': 'Adjusted Amount',
  'component.ecommerce.couponApplied': 'Coupon Applied',
  'component.ecommerce.rewardPointsApplied': 'Rewards Point Applied',
  'component.ecommerce.creditWalletApplied': 'Credit Wallet Applied',
  'component.ecommerce.actualDeliverydate': 'Actual Delivery Date',
  'component.ecommerce.dateModified': 'Date Modified',
  'component.ecommerce.returnAmount': 'Return Amount',
  'component.ecommerce.spAmount': 'Pay to Sp Amount',
  'component.ecommerce.delivery_timeslot': 'Delivery Timeslot',
  'component.ecommerce.status': 'Status',
  'component.catalog.help_category': 'Help Category',
  'component.catalog.help_category_plural': 'Help Categories',
  'component.catalog.category': 'Category',
  'component.catalog.timeslot': 'Timeslot',
  'component.catalog.day0': 'Sunday',
  'component.catalog.day1': 'Monday',
  'component.catalog.day2': 'Tuesday',
  'component.catalog.day3': 'Wednesday',
  'component.catalog.day4': 'Thursday',
  'component.catalog.day5': 'Friday',
  'component.catalog.day6': 'Saturday',
  'component.catalog.stores.pickdrop_difference': 'Pick and Drop Difference',
  'component.common.addtimeslot': 'Add Timeslot',
  'component.configuration.onboardings': 'Onboarding',
  'component.configuration.onboardings_plural': 'Onboardings',
  'component.configuration.app_menu': 'App Menu',
  'component.configuration.app_menu_plural': 'App Menu',
  'component.configuration.order_status': 'Order Status',
  'component.configuration.order_status_plural': 'Order Statuses',
  'component.configuration.notifications': 'Notification',
  'component.configuration.notifications_plural': 'Notifications',
  'component.configuration.context': 'Context',
  'component.configuration.page': 'Page',
  'component.configuration.page_plural': 'Pages',
  'component.marketing': 'Marketing',  
  'component.marketing.app_starts': 'App Start',
  'component.marketing.app_starts_plural': 'App Starts',
  'component.marketing.coupons': 'Coupon',
  'component.marketing.coupons_plural': 'Coupons',
  'component.marketing.sliders': 'Slider',
  'component.marketing.sliders_plural': 'Sliders',
  'component.coupon.type': 'Coupon Type',
  'component.coupon.deduct': 'Deduct',
  'component.coupon.cashback': 'Cashback',
  'component.coupon.discount_type': 'Discount Type',
  'component.coupon.discount_fixed': 'Fixed',
  'component.coupon.discount_percentage': 'Percentage',
  'component.wallet': 'Wallet',
  'component.wallet.customer_wallet_plural': 'Customer Wallet',
  'component.wallet.merchant_wallet_plural': 'Merchant Wallet',
  'component.wallet.admin_wallet_plural': 'Admin Wallet',
  'component.configuration': 'Localisation',
  'component.configuration.app_name': 'App name',
  'component.configuration.country_code': 'Country Code',
  'component.configuration.currency_code': 'Currency Code',
  'component.configuration.currency_symbol': 'Currency Symbol',
  'component.configuration.currencies': 'Currency',
  'component.configuration.currencies_plural': 'Currencies',
  'component.configuration.phone_regex': 'Phone Regex',
  'component.configuration.phone_code': 'Phone Country Code',
  'component.configuration.phone_min': 'Phone Minimum Length',
  'component.configuration.phone_max': 'Phone Maximum Length',
  'component.configuration.about_us': 'About Us Page',
  'component.configuration.lang_url': 'Language URL',
  'component.configuration.language': 'Default Language',
  'component.configuration.timezone': 'Default Timezone',
  'component.configuration.branch_key': 'Branch.io Key',
  'component.configuration.login_type': 'Login Type',
  'component.configuration.paypal_key': 'Paypal Key',
  'component.configuration.app_per_page': 'App Items Per Page',
  'component.configuration.reward_value': 'Reward Value',
  'component.configuration.applicationId': 'Auth Application ID',
  'component.configuration.privacy_policy': 'Privacy Policy',
  'component.configuration.referred_points': 'Referred Points',
  'component.configuration.referee_points': 'Referee Points',
  'component.configuration.reward_enabled': 'Rewards Enabled',
  'component.configuration.date_format_app': 'Date Format',
  'component.configuration.facebook_app_id': 'Facebook App ID',
  'component.configuration.return_policy_id': 'Return Policy',
  'component.configuration.paypal_test_mode': 'Paypal Test Mode',
  'component.configuration.stripe_test_mode': 'Stripe Test Mode',
  'component.configuration.googlefcm_api_key': 'Google FCM key',
  'component.configuration.app_language_version': 'App Language Version',
  'component.configuration.terms_and_conditions': 'Terms & Conditions',
  'component.configuration.ios_customer_app_link': 'iOS Customer App Link',
  'component.configuration.stripe_key_live_public': 'Stripe Live Public Key',
  'component.configuration.stripe_key_live_secret': 'Stripe Live Secret Key',
  'component.configuration.stripe_key_test_public': 'Stripe Test Public Key',
  'component.configuration.stripe_key_test_secret': 'Stripe Test Secret Key',
  'component.configuration.customer_app_update_ios': 'iOS Customer App Version',
  'component.configuration.merchant_app_update_ios': 'iOS Merchant App Version',
  'component.configuration.android_customer_app_link': 'Android Customer App Link',
  'component.configuration.customer_app_update_android': 'Android Customer App Version',
  'component.configuration.merchant_app_update_android': 'Android Merchant App Version',
  'component.configuration.login_required': 'Login Required',
  'component.configuration.vertical': 'Vertical',
  'component.miscellaneous': 'Miscellaneous',
  'component.miscellaneous.jobs_plural': 'Jobs',
  'component.miscellaneous.jobs': 'Job',
  'component.miscellaneous.export_import': 'Export/Import',
  'component.miscellaneous.import-categories': 'Import Categories',
  'component.miscellaneous.import-products': 'Import Products',
  'component.miscellaneous.import-products-options': 'Import Product Options',
  'component.miscellaneous.import-products-options-values': 'Import Product Option Values',
  'component.miscellaneous.export-orders': 'Export Orders',
  'component.miscellaneous.export-categories': 'Export Categories',
  'component.miscellaneous.export-products': 'Export Products',
  'component.miscellaneous.export-products-options': 'Export Product Options',
  'component.miscellaneous.export-products-options-values': 'Export Product Option Values',
  'component.miscellaneous.product-discount-cache': 'Product Discount Cache',
  'component.miscellaneous.parent-categories-discount-cache': 'Parent Categories Discount Cache',
  'component.miscellaneous.store-maximum-discount-cache': 'Store Maximum Discount Cache',
  'component.miscellaneous.pending-orders-failed': 'Long Pending Orders to Failed',
  'component.miscellaneous.source': 'Source',
  'component.miscellaneous.destination': 'Destination',
  'component.miscellaneous.information': 'Information',
  'component.miscellaneous.scripts': 'Scripts',
  'component.miscellaneous.run': 'Run',
  'error.common.blank': 'Field cannot be blank',
  'error.language.name': 'Field should be between 2 and 20 chars',
  'error.language.code': 'Field should be between 2 and 6 chars',
  'error.language.locale': 'Field should be between 2 and 50 chars',
  'error.filter.name': 'Field should be between 2 and 30 chars',
  'error.filter.description': 'Field should be between 5 and 200 chars',
  'error.filter.slug': 'Field should be between 3 and 60 chars',
  'error.filter.merchant_id': 'Field should be 36 chars',
  'error.image.size': 'Image must be smaller than 2MB!',
  'error.slider.size': 'Image must be smaller than 5MB!',
  'error.image.format': 'You can only upload JPG/PNG file!',
  'error.image.csvformat': 'You can only upload CSV file!',
  'error.catalog.question': 'Field should be between 10 and 100 chars',
  'error.catalog.answer': 'Field should be between 15 and 1000 chars',
  'error.configuration.currency': 'Field should be between 3 and 80 chars',
  'error.configuration.currency_code': 'Currency code is ISO code of 3 chars',
  'error.configuration.state_code': 'State code should be exactly 2 chars',
  'component.ecommerce.id': 'Id',
  'component.ecommerce.delivery_date': 'Delivery Date',
  'component.ecommerce.customer': 'Customer',
  'component.ecommerce.merchant': 'Merchant',
  'component.ecommerce.grand_total': 'Grand Total',
  'component.ecommerce.store_name': 'Store Name',
  'component.ecommerce.delivery_method': 'Delivery Method',
  'component.ecommerce.placed': 'Placed',
  'component.ecommerce.subtotal': 'Subtotal',
  'component.ecommerce.deliveryCharges': 'Delivery Charges',
  'component.ecommerce.amountPaid': 'Amount Paid (icl. of all taxes)',
  'component.ecommerce.action': 'Action',
  'component.ecommerce.edit': 'Edit',
  'component.ecommerce.view': 'View',
  'component.ecommerce.order': 'Order',
  'component.ecommerce.remove': 'Remove',
  'component.ecommerce.paymentMethod': 'Payment Method',
  'component.ecommerce.deliveryMethod': 'Delivery Method',
  'component.ecommerce.deliveryDate': 'Delivery Date',
  'component.ecommerce.address': 'Address',
  'component.ecommerce.itemName': 'Item Name',
  'component.ecommerce.description': 'Description',
  'component.ecommerce.total': 'Total',
  'component.ecommerce.quantity': 'Quantity',
  'component.ecommerce.unitTotal': 'Unit Total',
  'component.ecommerce.refrence': 'Reference',
  'component.ecommerce.addressName': 'Store Name',
  'component.ecommerce.ecom_order': 'Ecommerce : Orders',
  'component.ecommerce.active_order': 'Active Orders',
  'component.ecommerce.latest_order': 'Latest Orders',
  'component.ecommerce.new_order': 'New Order',
  'component.ecommerce.paid': 'Paid',
  'component.ecommerce.unpaid': 'Unpaid',
  'component.ecommerce.viewMap': 'View Map',
  'component.ecommerce.dateAdded': 'Date Added',
  'component.ecommerce.unitPrice': 'Unit Price',
  'component.ecommerce.product': 'Product',
  'component.ecommerce.paymentM': 'Payment Method',
  'component.ecommerce.orderNumber': 'Order Number',
  'component.ecommerce.customerId': 'Customer Id',
  'component.ecommerce.customerName': 'Customer Name',
  'component.ecommerce.orderStatus': 'Order Status',
  'component.ecommerce.notify': 'Notify Customer',
  'component.ecommerce.orderSubText': 'Order Sub-Text',
  'component.ecommerce.comment': 'Comment',
  'component.ecommerce.subText': 'Sub Text',
  'component.ecommerce.nyCustomer': 'Notified Customer',
  'component.ecommerce.yes': 'Yes',
  'component.ecommerce.no': 'No',
  'component.ecommerce.export_tooltip': 'Only filtered results can be exported due to huge data',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
